// https://github.com/brohlson/gatsby-plugin-anchor-links
// taken from above Github but modified

import React from 'react';
import { Link } from 'gatsby';
import { SCROLL_TO_EASE, 
         SCROLL_TO_DURATION, 
         scrollTo } from 'kh-common-components';


const isBrowser = typeof window !== "undefined";
const isDevelopment = process.env.NODE_ENV !== "production";

function scroller(target) {

    scrollTo(target);
}

function handleLinkClick(to, e) {

    /**
     * Log warnings on click
     */
    const improperFormatting = !to.includes("/") || !to.includes("#");
    if (improperFormatting && isDevelopment) {
        console.warn('Anchor path should contain an absolute root path `/` and selector `#` Ex: `/about#team`');
    }

    if (isBrowser && to.includes("#")) {
        const [anchorPath, anchor] = to.split("#");
        if (window.location.pathname === anchorPath) {
            e.preventDefault();
            scrollTo(`#${anchor}`);
        }
    }

}

 function handleStrippedLinkClick(to, e) {

    /**
     * Log warnings on click
     */
    const improperFormatting = !to.includes("/") || !to.includes("#");
    if (improperFormatting && isDevelopment) {
        console.warn('Anchor path should contain an absolute root path `/` and selector `#` Ex: `/about#team`');
    }

    const [anchorPath, anchor] = to.split("#");

    /**
     * Determine location, run scroller or set window variable
     */

    const isSamePage = isBrowser && window.location.pathname === anchorPath;
    const isDifferentPage = isBrowser && window.location.pathname !== anchorPath;

    if (isSamePage) {
        e.preventDefault();
        return scrollTo(`#${anchor}`);
    }

    if (isDifferentPage) {
        window.gatsby_scroll_hash = `#${anchor}`;
    }
}

function stripHashedLocation(to) {
    return to.split("#")[0];
}

 
export function LinkAnchor ({ to, title, children, className, stripHash = false }) {
    const linkProps = {
        to: stripHash ? stripHashedLocation(to) : to,
        onClick: e =>
        stripHash ? handleStrippedLinkClick(to, e) : handleLinkClick(to, e)
    };

    /**
     * Optional props
     */
    if (title) linkProps.title = title;
    if (className) linkProps.className = className;

    return <Link {...linkProps}>{Boolean(children) ? children : title}</Link>;
}