import React from 'react';

import SEO from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import Image from 'components/common/Image';
import TileCard from 'components/common/TileCard';

import BlogCarousel from 'components/cards/BlogCarousel';

import tileCardData from 'constants/about-us/tile-card';
import khHistory from 'images/about-us/kh-history.png';
import heroBanner from 'images/about-us/hero-cuppa.png';

import 'styles/pages/about.css';


function About() {

  return (
    <>
        <SEO title="About Kleenheat | Energy solutions provider in WA & NT" 
            description="Kleenheat supports the energy needs of homes and businesses in WA and the NT and is proudly part of Wesfarmers Chemicals, Energy & Fertilisers."
        />

        <Header />

        <LayoutMain>

            <LayoutPanel theme="mer-theme--cyan"
                            background="mer-bg--primary-cyan"
                            padding="mer-py-ju">
                <div className="container">
                    <div className="row mer-panel flex-row-reverse">
                        
                        <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg offset-sm-1">
                            <div className="content">
                                <h1>About us</h1>
                                <p>We’re a Western Australian born and bred company committed to supporting the energy needs of homes and businesses in WA and the NT.</p>
                                <p>Kleenheat is proudly part of Wesfarmers Chemicals, Energy & Fertilisers, a division of Wesfarmers Limited.</p>
                            </div>
                        </div>

                        <div className="mer-panel__images col-8 col-sm-4 d-none d-sm-block mr-sm-n5">
                            <img src={heroBanner} 
                                    className="mer-panel__image"
                                    alt="Cup of tea in a Kleenheat Mug" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-lighter"
                        border="mer-border--bottom"
                        padding="mer-py-sm">
                <div className="container">
                    <div className="row justify-content-center">
                        <TileCard data={tileCardData} />
                    </div>
                </div>
            </LayoutPanel> 


            <LayoutPanel background="mer-bg--ui-light"
                        padding="mer-pt-ju"
                        id="our-story">
                <div className="container">
                    <div className="row mer-panel kh-history">

                        <div className="col-10 col-sm-7">
                            <div className="content mer-text--size-lg">
                                <h2>Our story</h2>
                                <p>Kleenheat was formed in 1956 to provide Western Australian farming communities with access to reliable energy and appliances.</p>
                                <p>We pioneered the distribution of liquid petroleum gas (LPG) to regional Western Australia, initially sourcing gas from BP's plant in Kwinana. We would go on to open our own <a href="/about/our-plant" title="Learn more about our LPG plant">LPG plant</a> in 1988.</p>
                                <p>In the years since then, we've expanded our LPG offering with Kwik-Gas bottles, made our mark on the liquid natural gas (LNG) market, delivered reliable electricity solutions to businesses, and brought better value natural gas to WA homes.</p>
                                <p>We've always kept our focus on delivering genuine value and reliable support for our customers, and continue to look for ways to make energy easier for our customers.</p>
                            </div>
                        </div>

                        <div className="mer-panel__images col-10 col-sm-4 offset-sm-1">
                            <img src={khHistory} 
                                    className="mer-panel__image"
                                    alt="Old Kleenheat photos" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>



            <LayoutPanel background="mer-bg--ui-light"
                        padding="mer-py-ju"
                        id="our-people">

                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-10 col-sm-7 order-sm-2 offset-sm-1">
                            <div className="content mer-text--size-lg">
                                <h2>Our people</h2>
                                <p>Our people are at the heart of everything we do, and we embrace our team members’ unique skills, talents, backgrounds, and ideas.</p>
                                <p>We offer a range of great benefits and provide career development opportunities within Kleenheat, and across the broader Wesfarmers’ business.</p>
                                <p>Learn more about <a href="/about/people" title="Learn more about our people">our people</a> or <a href="/about/people/careers" title="Learn more about careers at Kleenheat">careers at Kleenheat.</a></p>
                            </div>
                        </div>


                        <div className="col-6 col-sm-4 col-lg-3 order-sm-1 mer-panel__images">
                            <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-diversity.svg" alt="Flowmoji diversity" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-lighter"
                        padding="mer-pt-ju"
                        id="sustainability">
                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-10 col-sm-7">
                            <div className="content mer-text--size-lg">
                                <h2>Sustainability</h2>
                                <p>We believe in a healthy, safe, and sustainable future for all, and are committed to a target of net zero scope 1 and 2 emissions by 2050.</p>
                                <p>We hold <a href="https://www.climateactive.org.au/buy-climate-active/certified-members/kleenheat" title="Learn more about our Climate Active certification in a new tab" target="_blank">Climate Active certification</a> for our carbon neutral natural gas, and give customers the option to offset the carbon associated with their natural gas use.</p>
                                <p>Read our <a href="/about/sustainability" title="Learn more about our sustainability priorities">sustainability priorities.</a></p>
                            </div>
                        </div>

                        <div className="mer-panel__images col-6 col-sm-4 col-lg-3 offset-sm-1">
                            <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-nature.svg" alt="Flowmoji nature" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>
            
            <LayoutPanel background="mer-bg--gs-grey-lighter"
                        padding="mer-py-ju"
                        id="community">

                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-10 col-sm-7 order-sm-2 offset-sm-1">
                            <div className="content mer-text--size-lg">
                                <h2>Partnerships and sponsorships</h2>
                                <p>We love to support events that bring the WA community together.</p>
                                <p>We also provide customer value and benefits through our long-running partnerships with RAC and Flybuys.</p>
                                <p>Learn more about our <a href="/about/community" title="Learn more about our community involvement">community involvement.</a></p>
                            </div>
                        </div>


                        <div className="col-6 col-sm-4 col-lg-3 order-sm-1 mer-panel__images">
                            <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-people.svg" alt="Flowmoji people" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--ui-light"
                        padding="mer-py-de"
                        id="media-releases">

                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-12">
                            <div className="row justify-content-around">

                                <div className="col-12 col-sm-5 content">
                                    <h3 className="mer-typography--headline5">News & Media</h3>
                                    <p>Read our latest <a href="/about/media-releases" title="Check out our latest media releases">media releases</a> or contact us with a <a href="/contact" title="Contact us">media enquiry</a>.</p>
                                    <p>You can also keep up to date with Kleenheat on <a href="https://www.facebook.com/kleenheat" title="Open Kleenheat's Facebook in a new tab" target="_blank">Facebook</a>, <a href="https://www.instagram.com/kleenheat/" title="Open Kleenheat's Instagram in a new tab" target="_blank">Instagram</a>, and <a href="https://www.linkedin.com/authwall?trk=qf&original_referer=https://www.google.com/&sessionRedirect=https:%2f%2fau.linkedin.com%2fcompany%2fwesfarmers-kleenheat-gas" title="Open Kleenheat's LinkedIn in a new tab" target="_blank">LinkedIn</a>.</p>
                                </div>
                                <div className="col-12 col-sm-5 content">
                                    <h3 className="mer-typography--headline5">Supplier information</h3>
                                    <p>Our suppliers play a key role in our business, so it’s important they’re safe, efficient, and innovative.</p>
                                    <p>View our <a href="/about/supplier-information" title="View supplier information">supplier policies.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-lighter"
                        padding="mer-py-lg"
                        id="governance">

                <div className="container">
                    <div className="row mer-panel">

                        <div className="col-10 col-sm-7 offset-sm-1 order-2">
                            <div className="content">
                                <h2>Corporate governance</h2>
                                <p>We’re committed to making positive economic, social, and environmental contributions to society while complying with all applicable laws and regulations.</p>
                                <p>
                                    The following policies support us in meeting these objectives:
                                    <ul>
                                        <li><a href="https://docova.wescef.com.au/docova/web/app.php/Docova/publicAccess?PublicAccessID=E7830B5B-65D7-4F0C-8CEF-81118D74EAB5" title="Open document in a new tab" target="_blank">WesCEF Whistleblower Policy</a></li>
                                        <li><a href="https://docova.wescef.com.au/docova/web/app.php/Docova/publicAccess?PublicAccessID=E3B31511-D19E-43DF-9BCD-9620C0A32742" title="Open document in a new tab" target="_blank">WesCEF Code of Conduct</a></li>
                                        <li><a href="https://docova.wescef.com.au/docova/web/app.php/Docova/publicAccess?PublicAccessID=2070B304-3201-4134-A009-91CF2F90F81F" title="Open document in a new tab" target="_blank">WesCEF Ethical Sourcing and Modern Slavery Policy</a></li>
                                        <li><a href="https://docova.wescef.com.au/docova/web/app.php/Docova/publicAccess?PublicAccessID=2CADB93F-4745-4C5B-9D71-8955ED93B36D2" title="Open document in a new tab" target="_blank">WesCEF Anti-Bribery Policy</a></li>
                                    </ul>
                                </p>
                                <p>For more information, <a href="/contact" title="Contact us">contact us</a>.</p>
                            </div>
                        </div>

                        <div className="col-6 col-sm-4 col-lg-3 mer-panel__images order-1">
                            <Image className="mer-panel__image" src="/icons/external/logo-wesfarmers.svg" alt="Wesfarmers logo" />
                        </div>
                    </div>
                </div>
            </LayoutPanel>
            
            
            <BlogCarousel id="discover"
                            panelBorder="mer-border--bottom"
                            panelBg="mer-bg--gs-white"
                            apiQuery="?posts_per_page=5"
                            ctaText="Discover more"
                            ctaLink="/blog"
                            ctaLinkClass="mer-button mer-button--secondary"
                            title="What's happening at Kleenheat"
            />

        </LayoutMain>

        <Footer />
    </>
  );
}

export default About;